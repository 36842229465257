import React, { useState, useEffect } from "react"
import styles from "./header.module.scss"
import logoImg from "../../images/logo.svg"
import { Link, navigate } from "gatsby"
import IconButton from "../../components/iconButton"
import MenuButton from "./menuButton"
import chevronIcon from "../../images/common/chevron_gray_down.svg"
import floorballIcon from "../../images/sports/icon/floorball.webp"
import fieldHockeyIcon from "../../images/sports/icon/field_hockey.webp"
import tennisIcon from "../../images/sports/icon/tennis.webp"
import SearchButton from "./searchButton"

const Header = ({ fixed, translations: _, languageCode, search }) => {
  const [isScrolledDown, setIsScrolledDown] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const lang = languageCode === "cs" ? "/cs" : ""

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev)
  }

  const handleScroll = () => {
    const position =
      document.body.scrollTop || document.documentElement.scrollTop
    if (position > 550 && !isScrolledDown) {
      setIsScrolledDown(true)
    } else if (position <= 550 && isScrolledDown) {
      setIsScrolledDown(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isScrolledDown])

  const sportButtons = (
    <>
      <IconButton
        icon="/static/f5e16dc203bd3f27d11e3a748002b6d4/basketball.svg"
        title={_.sports.basketball}
        onClick={() => navigate(`${lang}/blog/basketball`)}
      />
      <IconButton
        icon={fieldHockeyIcon}
        title={_.sports.field_hockey}
        onClick={() => navigate(`${lang}/blog/field-hockey`)}
      />
      <IconButton
        icon={floorballIcon}
        title={_.sports.floorball}
        onClick={() => navigate(`${lang}/blog/floorball`)}
      />
      <IconButton
        icon="/static/5d88e9b00fec1e4c224f4892af1379ec/handball.svg"
        title={_.sports.handball}
        onClick={() => navigate(`${lang}/blog/handball`)}
      />
      <IconButton
        icon="/static/d5b8ed706a918418731e42347b12c0ab/ice_hockey.svg"
        title={_.sports.ice_hockey}
        onClick={() => navigate(`${lang}/blog/ice-hockey`)}
      />
      <IconButton
        icon="/static/1aa9a9456b7dd669aa6b166f027077bf/performance.svg"
        title={_.sports.performance}
        onClick={() => navigate(`${lang}/blog/performance`)}
      />
      <IconButton
        icon="/static/6768a5d3580134ffc81709daf939f42e/soccer.svg"
        title={_.sports.soccer}
        onClick={() => navigate(`${lang}/blog/soccer`)}
      />
      <IconButton
        icon={tennisIcon}
        title={_.sports.tennis}
        onClick={() => navigate(`${lang}/blog/tennis`)}
      />
      <IconButton
        icon="/static/3882e324dff6c0d698ceed38af736049/volleyball.svg"
        title={_.sports.volleyball}
        onClick={() => navigate(`${lang}/blog/volleyball`)}
      />
      <button className={styles.otherSportsButton}>
        {_.sports.other_sports}
      </button>
      ,
    </>
  )

  return (
    <>
      <div
        className={
          fixed
            ? isScrolledDown
              ? [styles.header, styles.fixed].join(" ")
              : [styles.header, styles.fixed, styles.hidden].join(" ")
            : styles.header
        }
      >
        <div className={styles.logoWrapper}>
          <Link to={lang || "/"} className={styles.navLink}>
            <img src={logoImg} alt="logo" />
          </Link>
          <Link to={`${lang}/blog`} className={styles.blogTitle}>
            {_.blog.blog_title}
          </Link>
        </div>
        <div style={{ display: "flex" }}>
          <SearchButton onClick={search}/>
          <MenuButton onClick={toggleMenu} open={isMenuOpen} />
          <nav className={styles.desktopNavigation}>
            <Link to={`${lang}/blog`} className={styles.navLink}>
              {_.blog.all_title}
            </Link>
            <Link
              to={languageCode === "en" ? "/blog/news" : "/cs/blog/novinky"}
              className={styles.navLink}
            >
              {_.blog.news_title}
            </Link>
            <Link
              to={
                languageCode === "en"
                  ? "/blog/features"
                  : "/cs/blog/nove-funkce"
              }
              className={styles.navLink}
            >
              {_.blog.features_title}
            </Link>
            {languageCode === "en" && (
              <div className={[styles.navLink, styles.sportsLink].join(" ")}>
                {_.blog.sports_title}
                <div className={styles.sportsDropdown}>
                  <div className={styles.sportsList}>{sportButtons}</div>
                </div>
              </div>
            )}
            <a
              href="https://stories.sidelinesports.com"
              target="_blank"
              className={styles.navLink}
            >
              Stories
            </a>
          </nav>
        </div>
      </div>
      {fixed && (
        <nav
          className={
            isMenuOpen
              ? [styles.mobileNavigation, styles.open].join(" ")
              : styles.mobileNavigation
          }
        >
          <ul>
            <li>
              <Link to={`${lang}/blog`}>{_.blog.all_title}</Link>
            </li>
            <li>
              <Link
                to={languageCode === "en" ? "/blog/news" : "/cs/blog/novinky"}
              >
                {_.blog.news_title}
              </Link>
            </li>
            <li>
              <Link
                to={
                  languageCode === "en"
                    ? "/blog/features"
                    : "/cs/blog/nove-funkce"
                }
              >
                {_.blog.features_title}
              </Link>
            </li>
            {languageCode === "en" && (
              <li>
                <div
                  className={styles.sportsButton}
                  onClick={() => setIsDropdownOpen((prev) => !prev)}
                >
                  <span>{_.blog.sports_title}</span>
                  <img src={chevronIcon} alt="chevron" />
                </div>
                <div
                  className={
                    isDropdownOpen
                      ? [styles.sportsDropdown, styles.open].join(" ")
                      : styles.sportsDropdown
                  }
                >
                  <div>{sportButtons}</div>
                </div>
              </li>
            )}
            <li>
              <a
                href="https://stories.sidelinesports.com"
                target="_blank"
              >
                Stories
              </a>
            </li>
          </ul>
        </nav>
      )}
    </>
  )
}

export default Header
