import React from "react"
import searchIcon from "../../images/common/search.svg"
import styles from "./search.module.scss"

const SearchButton = (props) => {
  return (
    <button
      className={styles.searchButton}
      aria-label="search"
      {...props}
    >
      <img src={searchIcon} />
    </button>
  )
}

export default SearchButton
