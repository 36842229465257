import React from "react"
import { Link } from "gatsby"
import CategoryLabel from "./categoryLabel"
import styles from "./articlesTable.module.scss"

const ArticlesTable = ({ articles, itemsInRow, className, translations }) => {
  const transformedArticles = []
  for (let i = 0; i < articles.length; i += itemsInRow) {
    transformedArticles.push(articles.slice(i, i + itemsInRow))
  }
  return (
    <>
      <table
        className={[styles.articlesTable, className].join(" ")}
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          {transformedArticles.map((row, i) => [
            <tr key={`images-${i}`}>
              {row.map((article) => (
                <td key={article.id} style={{ position: "relative" }}>
                  <Link to={article.path}>
                    <div
                      className={styles.imageWrapper}
                      style={{
                        backgroundImage: `url("${
                          article.image ||
                          "https://blog.sidelinesports.com/wp-content/uploads/2020/12/XPS-profile-logo-official-new.png"
                        }")`,
                      }}
                    ></div>
                  </Link>
                </td>
              ))}
            </tr>,
            <tr key={`titles-${i}`}>
              {row.map((article) => (
                <td key={article.id}>
                  <Link to={article.path}>
                    <h3
                      dangerouslySetInnerHTML={{ __html: article.title }}
                    ></h3>
                  </Link>
                </td>
              ))}
            </tr>,
            <tr key={`tags-${i}`}>
              {row.map((article) => (
                <td key={article.id}>
                  <div className={styles.tags}>
                    {article.categories.map((c, i) => (
                      <CategoryLabel
                        key={`${i}-${c.name}`}
                        name={c.name}
                        path={c.path}
                        slug={c.slug}
                        transparent
                      />
                    ))}
                  </div>
                </td>
              ))}
            </tr>,
            <tr key={`info-${i}`}>
              {row.map((article) => (
                <td key={article.id}>
                  <Link to={article.path} className={styles.info}>
                    {new Date(article.date).toLocaleDateString("en-US")}{" "}
                    <span className={styles.dot}>•</span> {article.readingTime} {translations.blog.min_reading}
                  </Link>
                </td>
              ))}
            </tr>,
            <tr key={`descriptions-${i}`}>
              {row.map((article) => (
                <td key={article.id}>
                  <Link to={article.path}>
                    <div
                      className={styles.description}
                      dangerouslySetInnerHTML={{ __html: article.excerpt }}
                    ></div>
                  </Link>
                </td>
              ))}
            </tr>,
            <tr key={`readme-button-${i}`}>
              {row.map((article) => (
                <td key={article.id} className={styles.buttonWrapper}>
                  <Link to={article.path} className={styles.postLink}>
                    {translations.blog.read_more}
                  </Link>
                </td>
              ))}
            </tr>,
          ])}
        </tbody>
      </table>
    </>
  )
}

const ArticlesTableWrapper = ({ articles, translations }) => {
  return (
    <>
      <ArticlesTable
        articles={articles}
        className={styles.desktop}
        itemsInRow={3}
        translations={translations}
      />
      <ArticlesTable
        articles={articles}
        className={styles.tablet}
        itemsInRow={2}
        translations={translations}
      />
      <ArticlesTable
        articles={articles}
        className={styles.mobile}
        itemsInRow={1}
        translations={translations}
      />
    </>
  )
}

export default ArticlesTableWrapper
