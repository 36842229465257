import React, { useMemo, useState, useEffect, useCallback } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import searchIcon from "../../images/common/search.svg"
import closeIcon from "../../images/common/close.svg"
import spinnerImg from "../../images/blog/spinner-grey.svg"
import styles from "./search.module.scss"
import debounce from "lodash.debounce"
import axios from "axios"
import CategoryLabel from "./categoryLabel"

const RESULTS_LIMIT = 30

const Search = ({ close, lang }) => {
  const [viewportHeight, setViewportHeight] = useState(visualViewport.height)
  const [query, setQuery] = useState("")
  const [results, setResults] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const allPostsData = useStaticQuery(graphql`
    query AllPostsData {
      allBlogPost {
        nodes {
          postId
          categories {
            name
            path
            slug
          }
          imageMedium
          readingTime
          title
          path
          lang
          date
        }
      }
    }
  `).allBlogPost.nodes

  const allPosts = useMemo(
    () =>
      allPostsData.reduce((acc, curr) => ({ ...acc, [curr.postId]: curr }), {}),
    [allPostsData]
  )

  const handleInputChange = (e) => {
    setQuery(e.target.value)
  }

  const updateViewport = (e) => {
    setViewportHeight(e.target.height)
  }

  const fetchResults = async (q) => {
    setIsLoading(true)
    try {
      if (q.length > 1) {
        const results = await (
          await axios.get(
            `https://blog.sidelinesports.com/wp-json/wp/v2/search?search='${q}'&per_page=${RESULTS_LIMIT}&type=post&lang=${
              lang || "cs"
            }`
          )
        ).data
        setResults(
          results.map((r) => allPosts[r.id] || null).filter((r) => !!r)
        )
      } else {
        setResults([])
      }
    } catch (error) {
      setResults([])
      console.log(error)
    }
    setIsLoading(false)
  }

  const debouncedFetchResults = useCallback(
    debounce((q) => {
      fetchResults(q)
    }, 300),
    []
  )

  useEffect(() => {
    debouncedFetchResults(query)
  }, [query])

  useEffect(() => {
    const html = document.querySelector("html")
    html.style.overflow = "hidden"
    html.style.touchAction = "none"
    visualViewport.addEventListener("resize", updateViewport)
    return () => {
      html.style.overflow = "visible"
      html.style.touchAction = "auto"
      visualViewport.removeEventListener("resize", updateViewport)
    }
  }, [])

  return (
    <div className={styles.searchWrapper}>
      <div className={styles.search} style={{ maxHeight: viewportHeight }}>
        <div className={styles.searchBar}>
          <img src={searchIcon} className={styles.searchIcon} />
          <input
            type="text"
            placeholder="Search posts"
            autoFocus
            value={query}
            onChange={handleInputChange}
          />
          {isLoading && <img src={spinnerImg} className={styles.spinner} />}
          <button
            className={styles.closeButton}
            onClick={close}
            aria-label="close"
          >
            <img src={closeIcon} />
          </button>
        </div>
        {!!results.length && (
          <div className={styles.searchResults}>
            {results.map((r) => (
              <Link key={r.postId} to={r.path} className={styles.result}>
                <div className={styles.imageWrapper}>
                  <img src={r.imageMedium} />
                </div>
                <div className={styles.infoWrapper}>
                  <h3 dangerouslySetInnerHTML={{ __html: r.title }} />
                  <div>
                    {r.categories.map((c) => (
                      <CategoryLabel
                        key={c.slug}
                        name={c.name}
                        slug={c.slug}
                        transparent
                      />
                    ))}
                  </div>
                  <p>
                    {new Date(r.date).toLocaleDateString("en-US")}{" "}
                    <span className={styles.dot}>•</span> {r.readingTime}min
                  </p>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Search
