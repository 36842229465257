import React, { useState } from "react"
import styles from "./menuButton.module.scss"

const MenuButton = ({ onClick, open }) => {
  return (
    <button
      onClick={onClick}
      className={
        open ? [styles.menuButton, styles.open].join(" ") : styles.menuButton
      }
    >
      <div></div>
      <div></div>
      <div></div>
    </button>
  )
}

export default MenuButton
