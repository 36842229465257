import React from "react"
import styles from "./subscribe.module.scss"
import Button from "../button"
// import spinnerImg from "../../images/blog/spinner.svg"

const Subscribe = ({ translations, narrow }) => {
  // const [isLoading, setIsLoading] = useState(false)
  // const [isDone, setIsDone] = useState(false)
  // const [email, setEmail] = useState("")
  // const submit = async (e) => {
  //   e.preventDefault()
  //   if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
  //     return
  //   }
  //   setIsLoading(true)
  //   try {
  //     const response = await fetch(
  //       "https://i7sjujgi2e.execute-api.us-east-1.amazonaws.com/contactEmail",
  //       {
  //         method: "POST",
  //         body: JSON.stringify({
  //           location: window.location.href,
  //           name: "unknown",
  //           email,
  //           message: "Newsletter subscription request",
  //         }),
  //       }
  //     )
  //     if (response.ok) {
  //       setIsDone(true)
  //     } else {
  //       throw new Error("Something went wrong")
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   setIsLoading(false)
  // }

  return (
    <div
      className={
        narrow ? [styles.subscribe, styles.narrow].join(" ") : styles.subscribe
      }
    >
      <h2>{translations.blog.subscribe_newsletter}</h2>
      <Button
        anchor="http://eepurl.com/iF6yJY"
      >
        {translations.blog.subscribe}
      </Button>
      {/* <form onSubmit={submit}>
        <input
          type="text"
          placeholder={translations.blog.email_placeholder}
          disabled={isDone}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          type="submit"
          classStyle={styles.submitButton}
          disabled={isDone}
        >
          {isDone ? (
            "✓"
          ) : isLoading ? (
            <img src={spinnerImg} className={styles.spinner} />
          ) : (
            translations.blog.subscribe
          )}
        </Button>
      </form> */}
    </div>
  )
}

export default Subscribe
