import React from "react"
import styles from "./categoryLabel.module.scss"
import { Link } from "gatsby"

const colors = {
  ["news"]: "#77CC00",
  ["features"]: "#0073D9",
  ["novinky"]: "#77CC00",
  ["funkce"]: "#0073D9",
}

const CategoryLabel = ({ name, path, transparent, slug }) => {
  const style = {
    background: colors[slug] || "#64707A",
    opacity: transparent ? 0.7 : 1,
  }
  return path ? (
    <Link to={path} className={styles.categoryLabel} style={style}>
      {name}
    </Link>
  ) : (
    <div className={styles.categoryLabel} style={style}>
      {name}
    </div>
  )
}

export default CategoryLabel
